import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

const SubCategoryMobile = props => {
  const { category, match, nameTenant, t, tenants } = props;
  const params = match.params;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (params && !params.slug) {
      return;
    }
    setSelected(category.filter(x => x.slug === params.slug));
  }, [category]);

  const setCloseSubcategory = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };
  const openCategory = () => {
    setOpen(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <>
      <Link to={`/page/${tenants}`} className="v-subCategory__tenant">
        {nameTenant.length && nameTenant[0].name}
        <i className="fa fa-angle-double-right" aria-hidden="true" />
      </Link>
      <p className="v-subCategory__tenant v-subCategory--selected" onClick={() => openCategory()}>
        {selected.length ? selected[0].name : t('ribbon.txtSelectCategory')}
      </p>
      <div className="v-subCategory__dropdown" onClick={() => openCategory()}>
        <i className="fa fa-angle-down" aria-hidden="true" />
      </div>
      <div className={`v-subCategory__selector ${!open && 'hide'}`}>
        <div className="v-subCategory--close">
          <span onClick={() => setCloseSubcategory()}>x</span>
        </div>
        <hr />
        {category.map((items, index) => {
          return (
            <Link
              key={index}
              id={items.id}
              className={`v-subCategory__selector--subcategory ${
                params && params.slug === items.slug && 'v-subCategory--selected'
              }`}
              to={{
                pathname: `/category/${items.slug}`,
                search: `tenants=${tenants}`,
                state: { ribbon: tenants },
              }}
              onClick={() => setCloseSubcategory()}
            >
              {items.name}
            </Link>
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
  page: state.home.page,
  account: state.auth.account,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubCategoryMobile)),
);
