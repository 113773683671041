import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DivStyled } from '../views/styled';
import queryString from 'query-string';
import { unique } from '@/shared/utils/utils';
import SubCategoryMobile from './subCategoryMobile';

const Category = props => {
  const { category, params, tenants } = props;
  if (category.length === 0) return null;

  return category.map((items, index) => {
    return (
      <Link
        key={index}
        className={`v-subCategory--link ${params && params.slug === items.slug && 'active'}`}
        id={items.id}
        to={{
          pathname: `/category/${items.slug}`,
          search: `tenants=${tenants}`,
          state: { ribbon: tenants },
        }}
      >
        {items.name}
      </Link>
    );
  });
};
const SubCategory = props => {
  const { match, page, location, menu, isPortrait } = props;
  const params = match.params;
  const ribbonSubcategory = page && (page.ribbons_in_subcategory_section || []);
  let ribbonHorizontal = page && (page.ribbons || []);
  const [category, setCategory] = useState([]);
  const [nameTenant, setNametenant] = useState([]);
  let subNavigation = [];
  let isHas = false;
  let paramQuery = queryString.parse(location.search, { decode: false });
  let tenants = paramQuery.tenants || (params && params.slug) || (menu.length && menu[0].slug);

  const sortData = () => {
    if (!page || !page.side_navigation_section) {
      return ribbonHorizontal;
    }
    let { ribbons, side_navigation_section } = page;
    let list = [...ribbons, ...side_navigation_section];
    let ribbonTmp = unique(list).sort((a, b) => (a.odr > b.odr ? 1 : -1));
    return ribbonTmp;
  };

  useEffect(() => {
    if (!Array.isArray(ribbonSubcategory)) return;
    setNametenant(menu.filter(x => x.slug === tenants));
    if (params && params.slug) {
      isHas = ribbonSubcategory.some(element => element.slug === params.slug);
    }
    ribbonHorizontal = sortData();
    ribbonHorizontal &&
      ribbonHorizontal.length &&
      ribbonHorizontal.map(items => {
        if (!isHas && items.slug === params.slug) {
          subNavigation.push(items);
        }
      });
    setCategory(unique([...ribbonSubcategory, ...subNavigation]));
  }, [page && page.ribbons, params.slug]);

  return (
    <DivStyled props={{ isCategoryPage: props.categoryPage }}>
      <div className="v-subCategory--wrapSelect">
        <Category category={category} isPortrait={isPortrait} params={params} tenants={tenants} />
      </div>
      {category?.length ? (
        <SubCategoryMobile category={category} nameTenant={nameTenant} tenants={tenants} />
      ) : null}
    </DivStyled>
  );
};

const mapStateToProps = state => ({
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
  page: state.home.page,
  account: state.auth.account,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubCategory)),
);
